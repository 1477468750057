<!--
 * @Author: your name
 * @Date: 2020-09-18 11:46:48
 * @LastEditTime: 2020-09-21 20:18:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Web\JT.Web\src\views\leadingCockpit\components\accountDataDrill.vue
-->
<template>
    <div class="accountDataDrill">
        <h4>
            <p>{{ title }}</p>
            <div class="light-beam"></div>
        </h4>
        <div class="wrapper">
            <!-- 表格 -->
            <div class="table-con">
                <table class="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th
                                class="tit"
                                v-for="(item, index) in names"
                                :key="`names_${index}`"
                            >
                                {{ item }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="tit">
                                方量
                            </td>
                            <td class="num" v-for="(item, index) in quantity" :key="`quantity_${index}`">
                                {{ item }}
                            </td>
                        </tr>
                        <tr>
                            <td class="tit">
                                价格
                            </td>
                            <td class="num" v-for="(item, index) in price" :key="`price_${index}`">
                                {{ item }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- 图表 -->
            <div class="chart" id="chart"></div>
        </div>
    </div>
</template>

<script>
import {
    lineColumn,
} from './../../../../assets/js/charts';
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            // 月份
            month: 0,
            // 片区id
            pian_q_id: '',
            // 企业id
            qi_y_id: '',
            title: '',
            names: [],
            quantity: [],
            price: [],
            sourceData: [],
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.month = this.extr.month;
            this.pian_q_id = this.extr.pian_q_id;
            this.qi_y_id = this.extr.qi_y_id;
            this.title = this.extr.title;
            this.handleQuantity();
        },
        // 处理钻取片区量
        async handleQuantity() {
            const res = await this.getQuantity();
            if (res) {
                this.sourceData = res;
                res.map(item => {
                    this.names.push(item.name);
                    this.quantity.push(item.quantity);
                    this.price.push(item.price);
                });
                this.initChart();
            }
        },
        // 钻取片区量
        getQuantity() {
            const path = '/interfaceApi/report/leadercockpit/drill/area/quantityandprice/' + this.month + '/' + this.pian_q_id + '/' + this.qi_y_id;
            return this.$axios.$get(path, { defEx: true });
        },
        accountDrill(name) {
            let pian_q_id = '';
            let qi_y_id = '';
            if (this.pian_q_id === '-' && this.qi_y_id === '-') {
                const arr = this.sourceData.filter(item => item.name === name);
                if (arr.length > 0) {
                    pian_q_id = arr[0].code;
                    qi_y_id = '-';
                }
            } else if (this.qi_y_id === '-') {
                const arr = this.sourceData.filter(item => item.name === name);
                if (arr.length > 0) {
                    pian_q_id = this.pian_q_id;
                    qi_y_id = arr[0].code;
                }
            }
            this.$parent.hide();
            this.$toast({
                title: false,
                type: 'eject',
                width: '12rem',
                t_url: 'leadingCockpit/components/accountDataDrill/index',
                viewPosition: 'view',
                extr: {
                    month: this.month,
                    pian_q_id: pian_q_id,
                    qi_y_id: qi_y_id,
                    title: name,
                },
            });
        },
        initChart() {
            const data = {
                xAxis: { data: this.names },
                yAxis: [
                    {
                        name: '单位(万方)',
                        nameTextStyle: '',
                    },
                    {
                        name: '单位(元/方)',
                        nameTextStyle: '',
                    },
                ],
                series: [
                    {
                        type: 'bar',
                        name: '方量',
                        barWidth: 15,
                        data: this.quantity,
                        color: ['#23D2FD', '#2D7AE8'],
                    },
                    {
                        type: 'line',
                        name: '价格',
                        data: this.price,
                        color: ['#FC9444'],
                    },
                ],
            };
            this.lineColumn('chart', data);
        },
        // 折线柱图
        lineColumn(obj, data) {
            const lineColumnChart = window.$globalHub.$echarts.init(document.getElementById(obj));
            const option = {
                title: {
                    text: '',
                    x: 'center',
                    y: 0,
                    textStyle: {
                        color: '#B4B4B4',
                        fontSize: 16,
                        fontWeight: 'normal',
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                        label: {
                            show: true,
                        },
                    },
                },
                legend: {
                    textStyle: {
                        color: '#fff',
                    },
                    top: 0,
                    right: 10,
                },
                grid: {
                    left: 60,
                    top: 50,
                    right: 60,
                    bottom: 40,
                },
                xAxis: [{
                    // type:'category',
                    data: [],
                    boundaryGap: true,
                    axisLine: {
                        show: true,
                        color: 'rgba(255,255,255,0.1)',
                    },
                    splitArea: {
                        color: '#f00',
                        lineStyle: {
                            color: '#f00',
                        },
                    },
                    axisLabel: {
                        color: 'rgba(255,255,255,0.5)',
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                }],
                yAxis: [],
                series: [],
            };
            if (data) {
                if (data.grid) {
                    option.grid = data.grid;
                }

                const yAxis = [];
                const series = [];
                if (data.grid) {
                    option.grid = data.grid;
                }
                option.xAxis[0].data = data.xAxis.data;
                data.yAxis.forEach(item => {
                    if (item.show === 'false') {
                        item.show = false;
                    } else {
                        item.show = true;
                    }
                    yAxis.push({
                        name: item.name,
                        // nameLocation: 'middle',
                        nameTextStyle: {
                            color: 'rgba(255,255,255,0.5)',
                            padding: item.nameTextStyle,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(255,255,255,0.1)',
                                width: 0.5,
                                type: 'dashed',
                            },
                        },
                        axisLine: {
                            show: true,
                            color: 'rgba(255,255,255,0.1)',
                        },
                        axisLabel: {
                            show: true,
                            color: 'rgba(255,255,255,0.5)',
                        },
                        axisTick: {
                            show: false,
                        },
                        show: item.show,
                    });
                });
                option.yAxis = yAxis;
                data.series.forEach(item => {
                    let barYAxisIndex = 0;
                    let lineYAxisIndex = 1;
                    if (item.type === 'bar') {
                        if (item.yAxisIndex || item.yAxisIndex === 0) {
                            barYAxisIndex = item.yAxisIndex;
                        }
                        series.push({
                            name: item.name,
                            type: 'bar',
                            barWidth: item.barWidth,
                            yAxisIndex: barYAxisIndex,
                            itemStyle: {
                                normal: {
                                    color: new window.$globalHub.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: item.color[0],
                                    },
                                    {
                                        offset: 1,
                                        color: item.color[1],
                                    },
                                    ]),
                                },
                            },
                            data: item.data,
                        });
                    } else if (item.type === 'line') {
                        if (item.yAxisIndex || item.yAxisIndex === 0) {
                            lineYAxisIndex = item.yAxisIndex;
                        }
                        series.push({
                            name: item.name,
                            type: 'line',
                            // smooth: true,
                            showSymbol: true,
                            symbolSize: 8,
                            lineStyle: {
                                normal: {
                                    color: item.color[0],
                                },
                            },
                            yAxisIndex: lineYAxisIndex,
                            label: {
                                show: false,
                                position: 'top',
                                textStyle: {
                                    color: item.color[0],
                                },
                            },
                            itemStyle: {
                                color: '#6c50f3',
                                borderColor: '#fff',
                                borderWidth: 1,
                                shadowColor: 'rgba(0, 0, 0, .3)',
                                shadowBlur: 0,
                                shadowOffsetY: 2,
                                shadowOffsetX: 2,
                                normal: {
                                    color: item.color[0],
                                    lineStyle: {
                                        color: item.color[0],
                                    },
                                },
                            },
                            data: item.data,
                        });
                    }
                });
                option.series = series;
            }
            lineColumnChart.setOption(option);
            lineColumnChart.off('click');
            lineColumnChart.on('click', param => {
                if (param.name.indexOf('站') === -1) {
                    this.accountDrill(param.name);
                }
            });
        },

    },
};
</script>
<style lang="stylus">
@import './index.styl';
</style>